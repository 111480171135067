import { objectService } from '@/modules/common/api';
import { apiClient } from '@/provider';
import { prepareObject } from '@/provider/utils';
import geotagscheme from './geotag.graphql';
import { geotagservice } from '@/modules/geotags/api/index';

export default {
  ...objectService,
  fetchGeotagSchemaId() {
    return apiClient
      .query({
        query: geotagscheme.fetchGeotagSchemaId
      })
      .then(r => r.data.schemata[0]?.id || '');
  },
  async fetch(id) {
    const res = await apiClient.query({
      query: geotagscheme.fetch,
      variables: {
        id
      },
      fetchPolicy: 'no-cache'
    });
    return prepareObject(res.data.object);
  },
  async getRelationId(objectId, geotagId) {
    const res = await apiClient.query({
      query: geotagscheme.linkedGeotagsToObject,
      variables: {
        id: objectId
      },
      fetchPolicy: 'no-cache'
    });
    const relations =
      prepareObject(res.data.object)?.objectsToObjectsByObject1Id || [];
    return relations.find(({ object2 }) => object2.id === geotagId)?.id;
  },
  async updateLinkToObject(objectId, oldGeotagId, newGeotagId) {
    if (oldGeotagId) {
      const relationId = await geotagservice.getRelationId(
        objectId,
        oldGeotagId
      );
      if (relationId) {
        await objectService.unlink(relationId);
      }
    }
    if (newGeotagId) {
      await objectService.link(objectId, newGeotagId);
    }
  },
  create({ name, description, enabled, schemaId }) {
    return apiClient
      .mutate({
        mutation: geotagscheme.create,
        variables: {
          input: {
            object: {
              name,
              description,
              enabled,
              schemaId
            }
          }
        }
      })
      .then(r => prepareObject(r.data?.createObject?.object));
  },
  update(id, { name, description, enabled }) {
    return apiClient.mutate({
      mutation: geotagscheme.update,
      variables: {
        id,
        name,
        description,
        enabled
      }
    });
  }
};
